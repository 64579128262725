// Create Email -> Id fetch service
// Validate for changes in email and fetch id
const serverURL =
	'https://europe-west3-heyflow-clients.cloudfunctions.net/windmill-validate-v2';

let emailValidated = false;
let shadowRootHeyflow = document;

const discontinueOperation = () => {
	const buttonElement = shadowRootHeyflow
		.querySelector('section.visible')
		.querySelector('button.continue');
	buttonElement.style.background = 'red';
	buttonElement.querySelector('span').innerHTML =
		'Contact Windmill Microlending to Continue!';
	buttonElement.removeAttribute('onclick');
};

const verifyLoanExists = async (email) => {
	const verificationURL = `${serverURL}?email=${email}`;
	const response = await axios.get(verificationURL);
	if (response.data.loans) {
		discontinueOperation();
		swal(
			'Alert!',
			'You currently have an active loan application with Windmill Microlending. If you’d like to increase your loan amount, please contact us at info@teamwindmill.org or 1-855-423-2262 to discuss your options.',
			'error',
		);
	}
};

const validateEmail = async (email) => {
	console.log('Email to check', email);
	const data = {
		email: email,
	};
	const response = await axios.post(serverURL, data);
	if (response.data.customerId) return response.data.customerId;
	else return '';
};

// Helper Function to add entry to the specific field.
const addEntryToField = (data, fieldName) => {
	if (data && fieldName) {
		shadowRootHeyflow.querySelector(`[data-variable="${fieldName}"]`).value =
			data;
	}
};

const verifyCmrData = async (email) => {
	const customerId = await validateEmail(email);
	addEntryToField(customerId, 'CustomerId');
	await verifyLoanExists(email);
	emailValidated = true;
};

window.addEventListener('heyflow-change', async (event) => {
	if (!emailValidated) {
		const fieldsData = event.detail.fields;
		fieldsData.map(async (item) => {
			if (item.variable == 'Email') {
				let email = item.value;
				await verifyCmrData(email);
			}
		});
	}
});

window.addEventListener('heyflow-init', async () => {
	if (document.querySelector('heyflow-wrapper')?.shadowRoot) {
		shadowRootHeyflow = document.querySelector('heyflow-wrapper')?.shadowRoot;
	}
	const initialEmail = shadowRootHeyflow.querySelector(
		`[data-variable="Email"]`,
	).value;
	console.info('Initial Email to be verified: ', initialEmail);
	if (initialEmail != '') {
		await verifyCmrData(initialEmail);
	}
});
